import React from 'react'

interface Props {
  title?: string
  description?: string
  h3ClassName?: string
}

export default function Page({
  title,
  description,
  h3ClassName = 'max-w-3xl',
}: Props) {
  return (
    <>
      <h3
        className={`font-head font-extrabold md:leading-[60px] 
        px-8
        md:text-5xl text-2xl uppercase text-indigo-100 md:my-8 my-4 ${h3ClassName}`}
      >
        {title}
      </h3>
      <p
        className="md:text-lg text-sm text-center text-indigo-50 max-w-5xl
      px-5 md:leading-none leading-relaxed"
      >
        {description}
      </p>
    </>
  )
}
