import React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  className?: string
  textColor?: string
  highLightColor?: string
}

export default function ({
  className = '',
  textColor = 'text-gray-500',
  highLightColor = 'text-indigo-700',
}: Props) {
  const {t} = useTranslation()
  return (
    <div className={`md:w-auto w-full px-4 ${className}`}>
      <a
        href={process.env.GATSBY_AUTH + '/register/guard'}
        className={`block md:text-lg text-sm max-w-4xl mt-6 mb-4 ${textColor}`}
      >
        {t('Sign up for a')}
        <span className={`font-semibold mx-2 ${highLightColor}`}>
          {t('3-day free trial')}
        </span>
        {t('now.')}
      </a>
      <a
        href={process.env.GATSBY_AUTH + '/register/guard'}
        className="block mb-4 md:py-4 py-3 px-10 bg-green-500 text-white rounded-md"
      >
        {t('SIGN UP FOR FREE')}
      </a>
    </div>
  )
}
