import React, {useEffect, useRef} from 'react'
// @ts-ignore
import videoMP4 from 'assets/videos/evet.mp4'
// @ts-ignore
import videoWEBM from 'assets/videos/evet.webm'

interface Props {
  className?: string
}

function Page({className = ''}: Props) {
  const video = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (video.current) {
      if ('IntersectionObserver' in window) {
        const lazyVideoObserver = new IntersectionObserver(
          elements => {
            elements.forEach(element => {
              if (element.isIntersecting) {
                console.log('intersecting', {element})
                for (const source in video.current.children) {
                  const videoSource = video.current.children[
                    source
                  ] as HTMLSourceElement
                  if (
                    typeof videoSource.tagName === 'string' &&
                    videoSource.tagName === 'SOURCE'
                  ) {
                    videoSource.src = videoSource.dataset.src
                  }
                }
                video.current.load()
                lazyVideoObserver.unobserve(element.target)
              }
            })
          }
        )
        lazyVideoObserver.observe(video.current)
      } else {
        for (const source in video.current.children) {
          const videoSource = video.current.children[
            source
          ] as HTMLSourceElement
          if (
            typeof videoSource.tagName === 'string' &&
            videoSource.tagName === 'SOURCE'
          ) {
            videoSource.src = videoSource.dataset.src
          }
        }
        video.current.load()
      }
    }
    //eslint-disable-next-line
  }, [video])
  return (
    <>
      {/*<iframe*/}
      {/*  id="evet-video-iframe"*/}
      {/*  width="100%"*/}
      {/*  height="100%"*/}
      {/*  src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1&enablejsapi=1"*/}
      {/*  frameBorder="0"*/}
      {/*  className={`overflow-hidden ${className}`}*/}
      {/*/>*/}
      <div className={`overflow-hidden ${className}`}>
        <video
          ref={video}
          preload="yes"
          autoPlay
          loop
          muted
          playsInline
          width="100%"
          height="100%"
          className="rounded-xl"
        >
          <source data-src={videoMP4} type="video/mp4" />
          <source data-src={videoWEBM} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  )
}

export default Page
