import React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  className?: string
}

function Page({className = ''}: Props) {
  const {t} = useTranslation()
  return (
    <section
      className={`w-full flex flex-col items-center ${className}`}
    >
      <h2
        className="font-head font-extrabold md:text-5xl text-2xl uppercase
      text-gray-900 max-w-3xl md:px-0 px-4"
      >
        {t('Monitor Branded Keywords in Paid Search')}
      </h2>
      <div className="md:my-10 my-4 flex md:flex-row flex-col md:justify-center w-full">
        <div
          className="md:w-72 h-80 drop-shadow shadow-xl rounded-xl bg-white border-stone-50
            flex flex-col items-center justify-center
            md:border-4 border-8
            md:mx-5 mx-8 my-2
            "
        >
          <p className="text-7xl font-semibold text-center text-blue-500  mt-2">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="-ml-6 -mb-6 w-12 h-12 fill-blue-500"
              preserveAspectRatio="xMidYMid meet"
            >
              <path d="M26.5858 6.58579L25.8787 7.29289V7.29289L26.5858 6.58579ZM37.4142 17.4142L38.1213 16.7071L37.4142 17.4142ZM20 41C19.4477 41 19 41.4477 19 42C19 42.5523 19.4477 43 20 43V41ZM9 32C9 32.5523 9.44772 33 10 33C10.5523 33 11 32.5523 11 32H9ZM9.29289 41.2929C8.90237 41.6834 8.90237 42.3166 9.29289 42.7071C9.68342 43.0976 10.3166 43.0976 10.7071 42.7071L9.29289 41.2929ZM14 7H25.1716V5H14V7ZM37 18.8284V38H39V18.8284H37ZM25.8787 7.29289L36.7071 18.1213L38.1213 16.7071L27.2929 5.87868L25.8787 7.29289ZM39 18.8284C39 18.0328 38.6839 17.2697 38.1213 16.7071L36.7071 18.1213C36.8946 18.3089 37 18.5632 37 18.8284H39ZM25.1716 7C25.4368 7 25.6911 7.10536 25.8787 7.29289L27.2929 5.87868C26.7303 5.31607 25.9672 5 25.1716 5V7ZM34 43C36.7614 43 39 40.7614 39 38H37C37 39.6569 35.6569 41 34 41V43ZM11 10C11 8.34315 12.3431 7 14 7V5C11.2386 5 9 7.23858 9 10H11ZM34 41H20V43H34V41ZM11 32V10H9V32H11ZM29 28C29 30.7614 26.7614 33 24 33V35C27.866 35 31 31.866 31 28H29ZM19 28C19 25.2386 21.2386 23 24 23V21C20.134 21 17 24.134 17 28H19ZM24 23C26.7614 23 29 25.2386 29 28H31C31 24.134 27.866 21 24 21V23ZM19.0503 31.5355L9.29289 41.2929L10.7071 42.7071L20.4645 32.9497L19.0503 31.5355ZM24 33C22.619 33 21.3704 32.4415 20.4645 31.5355L19.0503 32.9497C20.3159 34.2154 22.0673 35 24 35V33ZM20.4645 31.5355C19.5585 30.6296 19 29.381 19 28H17C17 29.9327 17.7846 31.6841 19.0503 32.9497L20.4645 31.5355Z" />
            </svg>
            5
          </p>
          <p className="text-base font-semibold text-center uppercase text-gray-900 my-3">
            {t('Search Engines')}
          </p>
          <p className="text-sm text-center text-gray-500 leading-8 w-44">
            Google, Bing, Yahoo, Yandex, Aol
          </p>
        </div>

        <div
          className="md:w-72 h-80 drop-shadow shadow-xl rounded-xl bg-white border-stone-50
            flex flex-col items-center justify-center
            md:border-4 border-8
            md:mx-5 mx-8 my-2
            "
        >
          <p className="text-7xl font-semibold text-center text-green-500  mt-2">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="-ml-6 -mb-6 w-12 h-12 stroke-green-500"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                strokeWidth="2"
                d="M6.10986 22H10C12.2091 22 14 23.7909 14 26V28C14 30.2091 15.7909 32 18 32C20.2091 32 22 33.7909 22 36V41.8901M16 7.87104V11C16 13.7614 18.2386 16 21 16H22C24.2091 16 26 17.7909 26 20C26 22.2091 27.7909 24 30 24C32.2091 24 34 22.2091 34 20C34 17.7909 35.7909 16 38 16L40.129 16M30 40.9758V36C30 33.7909 31.7909 32 34 32H40.129M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24Z"
              />
            </svg>
            8
          </p>
          <p className="text-base font-semibold text-center uppercase text-gray-900 my-3">
            {t('Countries')}
          </p>
          <p className="text-sm text-center text-gray-500 leading-8">
            USA, UK, Canada, Australia, France, Germany,
            Netherlands, New Zealand
          </p>
        </div>

        <div
          className="md:w-72 h-80 drop-shadow shadow-xl rounded-xl bg-white border-stone-50
            flex flex-col items-center justify-center
            md:border-4 border-8
            md:mx-5 mx-8 my-2
            "
        >
          <p className="text-7xl font-semibold text-center text-orange-300 mt-2">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="-ml-6 -mb-6 w-12 h-12 stroke-orange-300"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                strokeWidth="2"
                d="M19.5 34L18 40L16 42H32L30 40L28.5 34M6 26H42M10 34H38C40.2091 34 42 32.2091 42 30V10C42 7.79086 40.2091 6 38 6H10C7.79086 6 6 7.79086 6 10V30C6 32.2091 7.79086 34 10 34Z"
              />
            </svg>
            3
          </p>
          <p className="text-base font-semibold text-center uppercase text-gray-900 my-3">
            {t('DEVICES')}
          </p>
          <p className="text-sm text-center text-gray-500 leading-8 w-44">
            Desktop, Mobile, <br />
            Tablet
          </p>
        </div>
      </div>
    </section>
  )
}

export default Page
