import React from 'react'
import {useTranslation} from 'react-i18next'
import SVGBidders from 'assets/svgs/SVGBidders.svg'
import SVGTotalPPC from 'assets/svgs/SVGTotalPPC.svg'
import SVGTable01 from 'assets/svgs/SVGTable01.svg'
import SVGTable02 from 'assets/svgs/SVGTable02.svg'
import SVGCard01 from 'assets/svgs/SVGCard01.svg'
import SVGCard02 from 'assets/svgs/SVGCard02.svg'
import SignUpForFree from './SignUpForFree'
import Header from './Section04Head'

interface Props {
  className?: string
}

function Page({className = ''}: Props) {
  const {t} = useTranslation()
  return (
    <section
      className={` flex flex-col items-center
     lg:rounded-3xl md:mx-2 
     bg-indigo-900 ${className}`}
    >
      <h6 className="text-base font-semibold text-center uppercase text-indigo-400 mt-16">
        {t('HOW IT WORKS')}
      </h6>

      <Header
        title={t('Automated Paid Search Monitoring')}
        description={t(
          'Monitor, Analyze, and Investigate the initial search compliance results, all in one place.'
        )}
      />
      <div className="w-full md:my-12 my-4 xl:p-0 p-4 flex lg:flex-row flex-col items-center justify-center">
        <div className="xl:mx-4 sm:mx-2 mx-0 my-2 rounded-2xl overflow-hidden">
          <img
            src={SVGBidders}
            alt={t('Bidders')}
            className="object-contain"
          />
        </div>{' '}
        <div className="xl:mx-4 sm:mx-2 mx-0 my-2 rounded-2xl overflow-hidden">
          <img
            src={SVGTotalPPC}
            alt={t('Total PPC')}
            className="object-contain"
          />
        </div>
      </div>

      <Header
        title={t('Full View of All PPC Ads Results')}
        description={t(
          'Evet makes comprehensive search data accessible for all teams.'
        )}
      />
      <div className="w-full md:my-12 my-4 xl:p-0 p-4 flex items-center justify-center">
        <img
          src={SVGTable01}
          alt={t('All PPC Report')}
          className="object-contain"
        />
      </div>

      <Header
        title={t('Full Ad Details')}
        description={t(
          'Access Incomparable Insights of Each PPC Ad'
        )}
      />
      <div className="w-full md:my-12 my-4 xl:p-0 p-4 flex items-center justify-center">
        <img
          src={SVGTable02}
          alt={t('Details Report')}
          className="object-contain"
        />
      </div>

      <Header
        title={t('Ad Content Accuracy Report')}
        h3ClassName="max-w-5xl"
        description={t(
          'Review Ad Headlines, Descriptions, and URLs, all in one place. Find out if any of your affiliates and partners use inaccurate Ad Copies in order to get higher click-throughs (such as invalid coupon or sale offer)'
        )}
      />
      <div className="w-full md:my-12 my-4 md:p-2 p-4 flex lg:flex-row flex-col items-center justify-center">
        <div className="xl:mx-4 sm:mx-2 mx-0 my-2 rounded-2xl overflow-hidden">
          <img
            src={SVGCard01}
            alt={t('Accuracy Report')}
            className="object-contain"
          />
        </div>
        <div className="xl:mx-4 sm:mx-2 mx-0 my-2 rounded-2xl overflow-hidden">
          <img
            src={SVGCard02}
            alt={t('Accuracy Report')}
            className="object-contain"
          />
        </div>
      </div>

      <SignUpForFree
        className="mb-10"
        textColor="text-gray-100"
        highLightColor="text-amber-300"
      />
    </section>
  )
}

export default Page
