import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Layout} from 'layouts'
import Section01 from './Section01'
import Section02 from './Section02'
import SearchEngineLogos from './SearchEngineLogos'
import Video from './Video'
import Section03 from './Section03'
import Section04 from './Section04'
import SignUpForFree from './SignUpForFree'

function Page() {
  const {t} = useTranslation()

  return (
    <Layout
      title=""
      description={t(
        'Monitor who is bidding on your brand and protect your Ad dollars from bid inflation. Recover traffic from competitors. Ensure Affiliates & Partners bid on approved keywords.'
      )}
      className="bg-[url(assets/images/bg-landing.svg)] bg-contain bg-top bg-no-repeat"
    >
      <section className="flex flex-col items-center text-center text-gray-900">
        <h1 className="font-head font-extrabold md:text-5xl text-2xl mt-10">
          <Trans>{t('PAID SEARCH<br/> MONITORING TOOL')}</Trans>
        </h1>
        <p className="md:text-3xl text-xl my-8 max-w-4xl">
          <Trans>For Ads on Google, Bing and Yahoo.</Trans>
        </p>
        <p className="md:text-lg text-sm text-center text-gray-500 max-w-3xl p-4">
          <Trans>
            {t(
              'Monitor who is bidding on your brand and protect your ' +
                'Ad dollars from bid inflation. Recover traffic from competitors. ' +
                'Ensure Affiliates & Partners bid on approved keywords.'
            )}
          </Trans>
        </p>

        <SignUpForFree />

        <div className="xl:mx-0 mx-4 md:my-8 my-0">
          <Video className="xl:w-[1020px] xl:h-[570px] w-full border-4 rounded-xl bg-white" />
        </div>

        <p
          className="font-semibold text-center uppercase text-gray-900
        md:text-base text-xs
        md:my-10 my-6 px-4"
        >
          {t('Monitoring Paid Search Ads on five search engines')}
        </p>

        <SearchEngineLogos className="md:mb-10" />
        <Section01 className="my-10" />
        <Section02 className="xl:px-0 px-4" />
        <Section03 className="md:my-10" />
        <Section04 className="md:my-10" />
      </section>
    </Layout>
  )
}

export default Page
